<template>
  <van-popup v-model="isRecommendedShow" @close="close" :close-on-click-overlay="false" :overlay-style="{ 'background-color': '#333' }" position="center" class="pop-goods">
    <div class="goods-card">
      <!-- 状态 -->
      <div class="status-block col-start-center">
        <img :src="`https://img.chaolu.com.cn/ACT/marketing/${queryObj.result == 0 ? 'gantan' : 'dui'}-icon.png`" alt="" />
        <p class="s1 f36 fw6">{{ queryObj.notice ? '提交成功' : queryObj.result == 1 ? '签到成功' : '签到失败' }}</p>
        <p class="s2 f26">{{ queryObj.reason }}</p>
        <p v-if="queryObj.equityCardExpiredTip" class="s3 f20 row-center-center">{{ queryObj.equityCardExpiredTip }}</p>
      </div>
      <!-- 优惠券信息 -->
      <div v-if="voucherInfo" class="voucher-block fw6 row-center-center">
        <div class="left red col-center-center flex-none">
          <p class="f32">
            ¥ <span class="f72 BebasNeueBold">{{ voucherInfo.discount }}</span>
          </p>
          <p class="f20 fw6">满{{ voucherInfo.spendmoney }}元可用</p>
        </div>
        <div class="right flex-auto">
          <p class="f28 ellipsis">{{ voucherInfo.name }}</p>
          <div v-show="voucherInfo.type" class="v-type f20 row-center-center red">{{ voucherType[voucherInfo.type] }}</div>
          <p class="f20 fw4">{{ `${voucherInfo.validStartTime}-${voucherInfo.validEndTime}` }}</p>
        </div>
      </div>
      <!-- 带货人 信息 -->
      <div class="invite-block row-start-center f22">
        <div class="invite-head"><img :src="`${inviteUserInfo.shareUserHeadImg}`" alt="" /></div>
        <div>
          <p class="invite-name fw6 ellipsis">{{ inviteUserInfo.shareTeacherId ? '超鹿教练' : '' }} {{ inviteUserInfo.shareTitle }}</p>
          <p>为您推荐健身福利</p>
        </div>
      </div>
      <!-- 商品 -->
      <template v-if="queryObj.goodsId && showGoods">
        <div class="goods-block flex" v-for="(item, index) in goodsInfo" :key="index">
          <div class="goods-img"><img :src="item.goodsImg" /></div>
          <div class="goods-info">
            <div class="goods-name f28 fw6 ellipsis">{{ item.goodsName }}</div>
            <div class="invalid-time f20" v-if="item.hint">
              {{ item.hint }}
            </div>
            <div class="label-line" v-if="item.productTags.length">
              <div class="label-model f20 fw6" v-for="(tag, index) in goodsInfo.productTags" :key="index">
                {{ tag }}
              </div>
            </div>
            <div class="last-line row-between-start">
              <div class="price-line">
                <span v-if="item.priceStatus.showAfterVoucher" class="dl f22 fw6">券后价</span>
                <span class="dl f22 fw6">¥</span>
                <span class="price f32 fw6">{{ item.priceStatus.discountPrice === undefined ? item.salePrice : item.priceStatus.discountPrice }}<span v-if="item.priceStatus.showStart || item.showStart">起</span></span>
                <span v-if="item.displayOriginPrice" class="ori-price f20">¥{{ item.originPrice }}</span>
              </div>
            </div>
            <div class="row-end-center">
              <div :class="['btn', 'f24', 'fw6', 'row-center-center', item.status === 'CAN_BUY' ? '' : 'gray']" @click="buyCard(item)">
                {{ buttonText }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <div :class="['button', 'f28', 'fw6', 'row-center-center']" @click="close">查看更多优惠</div>
    </div>
    <img class="popclose" src="https://img.chaolu.com.cn/ACT/micro-invite-202306/popclose.png" @click="newAppBack" />
  </van-popup>
</template>
<script>
import buyStatus from '../config'
import { newAppBack } from '@/lib/appMethod'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      isRecommendedShow: true,
      goodsInfo: [
        {
          productTags: [],
          status: 'CAN_BUY',
          priceStatus: {},
        },
      ], // 他人分享的单个商品信息
      voucherInfo: null,
      voucherType: {
        0: '',
        1: '团课',
        2: '自助',
        3: '通用',
        8: '私教',
        9: '实物',
        11: '训练营',
        14: '自助卡',
        16: '小班课',
        19: '团课卡',
        22: '实体卡兑换券',
        24: '包月私教',
        25: '超鹿月卡'
      },
      showGoods: false
    }
  },
  props: {
    inviteUserInfo: {
      type: Object,
    },
  },
  computed: {
    ...mapState(['userId']),
    buttonText() {
      return '马上抢'
    },
  },
  created() {
    const q = this.$route.query

    this.queryObj = q
    console.log('qqqq:', q)
    // 是否是分享单个商品
    if (this.queryObj.goodsId) {
      const ids = Array.isArray(this.queryObj.goodsId) ? this.queryObj.goodsId : this.queryObj.goodsId.split(',')
      this.queryObj.goodsId = ids
      this.getGoodInfo()
    }
    if (this.queryObj.userVoucherIds) {
      this.getVoucherInfo()
    }
  },
  methods: {
    newAppBack,
    buyCard(item) {
      if (item.status !== 'CAN_BUY') return
      this.$emit('buyCard', { ...item, isRecommended: true })
    },
    // 获取他人分享多个商品的弹窗信息  之前是一个 现在可以是多个
    getGoodInfo() {
      const params = {
        goodsId: this.queryObj.goodsId,
        shopId: this.queryObj.id,
        userId: this.userId,
      }

      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsInfoList`, params).then((res) => {
        this.goodsInfo = res.data
        this.goodsInfo.map((item) => {
          item.productTags = item.productTags ? item.productTags.split(',') : []
        })

        // 按钮状态
        this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsStatus`, params).then((res) => {
          this.goodsInfo.map((item) => {
            item.status = res.data.goodsStatusMap[item.id * 1]
          })
        })
        // 获取价格的状态
        this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodMaxDiscount`, params).then((res) => {
          this.goodsInfo.map((item) => {
            this.$set(item, 'priceStatus', res.data.goodsDiscountMap[item.id * 1])
          })
          this.showGoods = true
        })
      }).catch(() => {
        this.close()
      })
    },
    getVoucherInfo() {
      this.$axios
        .post(`${this.baseURLApp}/user/voucher/batchQuery/detail`, {
          userVoucherIds: this.queryObj.userVoucherIds.split(','),
        })
        .then((res) => {
          this.voucherInfo = res.data[0]
          this.voucherInfo.validStartTime = this.voucherInfo.validStartTime.split(' ')[0]
          this.voucherInfo.validEndTime = this.voucherInfo.validEndTime.split(' ')[0]
        })
    },
    close() {
      this.isRecommendedShow = false
      this.$emit('close')
    },
  },
}
</script>
<style lang="less" scoped>
.pop-goods {
  background: transparent;

  .popclose {
    width: 60px;
    height: 60px;
    display: block;
    margin: 64px auto 0;
  }
}

.goods-card {
  width: 622px;
  box-sizing: border-box;
  padding: 24px;
  background: #fff;
  border-radius: 16px;
  overflow: hidden;
  position: relative;

  &:last-of-type {
    margin: 0;
  }
  .status-block {
    padding: 18px 0 32px;
    img {
      width: 116px;
    }
    .s1 {
      margin: 26px 0 20px;
    }
    .s3 {
      width: 100%;
      box-sizing: border-box;
      padding: 10px 17px;
      color: #fb453c;
      background: #fff3f1;
      border-radius: 4px;
      margin: 12px 0 0;
    }
  }
  .voucher-block {
    width: 520px;
    height: 214px;
    background-image: url(https://img.chaolu.com.cn/ACT/marketing/voucher-bg.png);
    background-size: cover;
    margin: 0 auto 20px;
    .left {
      width: 39%;
    }
    .right {
      padding: 0 0 0 32px;
      text-align: left;
      box-sizing: border-box;
      overflow: hidden;
      .v-type {
        width: fit-content;
        margin: 8px 0 12px;
        padding: 0 15px;
        height: 30px;
        background: #fff7f6;
        border-radius: 16px;
        border: 1px solid #f84326;
      }
    }
  }
  .invite-block {
    width: 100%;
    padding: 32px 0 0;
    border-top: 2px solid #eee;

    .invite-head {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      background: #f5f5f5;
      flex-shrink: 0;
      margin-right: 20px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    .invite-name {
      margin: 0 0 8px;
    }
  }

  .goods-block {
    padding: 24px 20px;
    margin: 28px 0 0;
    background-color: #f7f7f7;
    border-radius: 10px;
  }

  .goods-img {
    width: 200px;
    margin-right: 16px;

    img {
      width: 100%;
      border-radius: 16px;
      display: block;
    }
  }

  .goods-info {
    position: relative;
    overflow: hidden;
    flex: 1;

    .goods-name {
      color: #242831;
    }

    .last-line {
      margin: 16px 0;
    }
    .btn {
      width: 120px;
      height: 50px;
      background: linear-gradient(0deg, #f35947 0%, #fba27b 100%);
      border-radius: 25px;
      color: #fff;
    }
  }

  .invalid-time {
    color: #242831;
    margin-top: 8px;
  }

  .label-line {
    white-space: nowrap;
    margin-top: 4px;

    .label-model {
      padding: 0 10px;
      background: linear-gradient(90deg, #ffe8b5 0%, #ffd7c3 100%);
      border-radius: 4px;
      color: #b12e1d;
      margin-right: 10px;
      line-height: 30px;
      display: inline-flex;
      max-width: 100%;
      overflow: hidden;
    }
  }

  .price-line {
    margin-top: 8px;
    line-height: 1;
    display: flex;
    align-items: flex-end;

    .dl {
      color: #ee4832;
      margin-right: 4px;
    }

    .price {
      color: #ee4832;
      margin-right: 9px;
      position: relative;
      top: 2px;
    }

    .ori-price {
      text-decoration: line-through;
      color: #6c727a;
    }
  }

  .send {
    position: absolute;
    left: 0;
    top: 0;
    border-bottom-right-radius: 16px;
    background: linear-gradient(90deg, #3889f0 0%, #a26bdc 99%);
    padding: 0 18px;
    line-height: 38px;
    color: #fff;
  }

  .button {
    width: 100%;
    height: 96px;
    background: #ffde00;
    border-radius: 8px;
    margin: 24px 0 0;
  }

  .gray {
    color: #696c6f;
    background: linear-gradient(0deg, #d7d7d7 0%, #cfd0d2 100%);
  }
  .red {
    color: #f84326;
  }
}
</style>
