<template>
  <van-popup v-model="isRecommendedShow" @close="close" :close-on-click-overlay="false" :overlay-style="{'background-color': '#333'}" position="center" class="pop-goods">

    <div class="goods-card">
      <!-- 带货人 信息 -->
      <div class="invite-block row-start-center f22">
        <div class="invite-head"><img :src="`${inviteUserInfo.shareUserHeadImg}`" alt="" /></div>
        <div>
          <p class="invite-name fw6 ellipsis">{{ inviteUserInfo.shareTeacherId ? '超鹿教练' : '' }} {{ inviteUserInfo.shareTitle }}</p>
          <p>给您推荐了一个商品</p>
        </div>
      </div>
      <!-- 商品 -->
      <div class="goods-block flex">
        <div class="goods-img"><img :src="goodsInfo.goodsImg" /></div>
        <div class="goods-info">
          <div class="goods-name f28 fw6 ellipsis">{{ goodsInfo.goodsName }}</div>
          <div class="invalid-time f20" v-if="goodsInfo.hint">
            {{ goodsInfo.hint }}
          </div>
          <div class="label-line" v-if="goodsInfo.productTags.length">
            <div class="label-model f20 fw6" v-for="item, index in goodsInfo.productTags" :key="index">
              {{ item }}
            </div>

          </div>
          <div class="last-line row-between-start">
            <div class="price-line">
              <span v-if="goodsInfo.priceStatus.showAfterVoucher" class="dl f22 fw6">券后价</span>
              <span class="dl f22 fw6">¥</span>
              <span class="price f32 fw6">{{ goodsInfo.priceStatus.discountPrice === undefined ? goodsInfo.salePrice : goodsInfo.priceStatus.discountPrice }}<span v-if="goodsInfo.priceStatus.showStart || goodsInfo.showStart">起</span></span>
              <span v-if="goodsInfo.displayOriginPrice" class="ori-price f20">¥{{ goodsInfo.originPrice }}</span>
            </div>
          </div>
        </div>
      </div>
      <div :class="['button', 'f28', 'fw6', 'row-center-center', goodsInfo.status === 'CAN_BUY' ? '' : 'gray']"
        @click="buyCard">
        {{ buttonText }}
      </div>
    </div>
    <!-- <img class="popclose" src="https://img.chaolu.com.cn/ACT/micro-invite-202306/popclose.png"
      @click="close" /> -->
  </van-popup>
</template>
<script>
import buyStatus from '../config'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      shopId: '',
      goodsId: '',

      isRecommendedShow: false,
      goodsInfo: {
        productTags: [],
        status: 'CAN_BUY',
        priceStatus: {}
      }, // 他人分享的单个商品信息
    }
  },
  props: {
    inviteUserInfo: {
      type: Object
    }
  },
  computed: {
    ...mapState(['userId']),
    buttonText() {
      if (this.goodsInfo.status === 'CAN_BUY') {
        return '立即购买'
      }
      if (this.goodsInfo.status === 'NOT_BUY') {
        return '不符合购买条件'
      }
      return buyStatus[this.goodsInfo.status] ? buyStatus[this.goodsInfo.status].name : '立即购买'
    }
  },
  created() {
    // 是否是分享单个商品
    this.shopId = this.$route.query.id
    this.goodsId = this.$route.query.goodsId
    if (this.goodsId) {
      this.getSingleGoodInfo()
    }
  },
  methods: {
    buyCard() {
      if (this.goodsInfo.status !== 'CAN_BUY') return
      this.$emit('buyCard', { ...this.goodsInfo, isRecommended: true })
    },
    // 获取 他人分享单个商品 的弹窗信息
    getSingleGoodInfo() {
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsInfo`, {
        goodsId: this.goodsId,
        shopId: this.shopId,
        userId: this.userId,
      }).then(res => {
        this.goodsInfo = res.data
        this.goodsInfo.priceStatus = {}
        this.goodsInfo.productTags = this.goodsInfo.productTags ? this.goodsInfo.productTags.split(',') : []

        const params = {
          goodsId: [this.goodsId],
          shopId: this.shopId,
          userId: this.userId,
        }
        // 获取价格的状态
        this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodMaxDiscount`, params).then(res => {
          let obj = res.data.goodsDiscountMap[this.goodsId * 1] || {showAfterVoucher: false}
          this.$set('goodsInfo', 'priceStatus', obj)
        })
        // 获取按钮状态
        this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsStatus`, params).then(res => {
          this.goodsInfo.status = res.data.goodsStatusMap[this.goodsId * 1]
          this.isRecommendedShow = true
        })
      })

    },
    close() {
      this.isRecommendedShow = false
      this.$emit('close')
    }
  }
};
</script>
<style lang="less" scoped>
.pop-goods {
  background: transparent;

  .popclose {
    width: 60px;
    height: 60px;
    display: block;
    margin: 64px auto 0;
  }
}

.goods-card {
  width: 622px;
  box-sizing: border-box;
  padding: 24px;
  background: #fff;
  border-radius: 16px;
  overflow: hidden;
  position: relative;

  &:last-of-type {
    margin: 0;
  }

  .invite-block {
    width: 100%;
    padding: 0 0 32px;
    border-bottom: 2px solid #eee;

    .invite-head {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      background: #f5f5f5;
      flex-shrink: 0;
      margin-right: 20px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    .invite-name {
      margin: 0 0 8px;
    }
  }

  .goods-block {
    padding: 32px 0 0;
  }

  .goods-img {
    width: 220px;
    margin-right: 23px;

    img {
      width: 100%;
      border-radius: 16px;
      display: block;
    }
  }

  .goods-info {
    position: relative;
    overflow: hidden;
    flex: 1;

    .goods-name {
      color: #242831;
    }

    .last-line {
      margin-top: 8px;
    }
  }

  .invalid-time {
    color: #242831;
    margin-top: 12px;
  }

  .label-line {
    white-space: nowrap;
    margin-top: 8px;

    .label-model {
      padding: 0 10px;
      background: linear-gradient(90deg, #FF7E18 0%, #FF634E 100%);
      border-radius: 4px;
      color: #fff;
      margin-right: 10px;
      line-height: 32px;
      display: inline-flex;
      max-width: 100%;
      overflow: hidden;
    }
  }

  .price-line {
    margin-top: 8px;
    line-height: 1;
    display: flex;
    align-items: flex-end;

    .dl {
      color: #EE4832;
      margin-right: 4px;
    }

    .price {
      color: #EE4832;
      margin-right: 9px;
      position: relative;
      top: 2px;
    }

    .ori-price {
      text-decoration: line-through;
      color: #6C727A;
    }
  }

  .send {
    position: absolute;
    left: 0;
    top: 0;
    border-bottom-right-radius: 16px;
    background: linear-gradient(90deg, #3889F0 0%, #A26BDC 99%);
    padding: 0 18px;
    line-height: 38px;
    color: #fff;
  }

  .button {
    width: 100%;
    height: 96px;
    background: #FFDE00;
    border-radius: 8px;
    margin: 60px 0 0;
  }

  .gray {
    color: #696c6f;
    background: linear-gradient(0deg, #d7d7d7 0%, #cfd0d2 100%);
  }
}</style>
