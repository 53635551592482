<template>
  <div class="container col-start-start">
    <nav-bar headerTitle="组合购买" :showBack="true" :header-back="newAppBack"></nav-bar>
    <p class="title f24">共{{ shopCartInfo.badgeNum }}件商品</p>

    <div class="goods-box flex-auto">
      <goods-item class="goods-item" v-for="v in shopCartList.goodsList" :item="v" type="phone" :key="v.id" :isCanAdd="false" :num="shopCartInfo.selectionCount[v.id]"></goods-item>
    </div>

    <!-- 吸底 -->
    <div class="sticky-bottom row-between-center f18 fw6">
      <div class="row-start-end">
        <p class="p1 f24">实付</p>
        <p class="p2 f56 BebasNeueBold"><span class="f28">¥</span>{{ shopCartInfo.salePrice }}</p>
        <p class="p3 f24 BebasNeueBold">¥{{ shopCartInfo.originPrice }}</p>
        <div v-if="shopCartInfo.deductionPrice" class="p4 row-center-center f20 fw6">立省{{ shopCartInfo.deductionPrice }}元</div>
      </div>
      <div v-show="shopCartList.goodsList" @click="payAction" class="button f32 fw6 row-center-center">立即购买</div>
    </div>

    <!-- <payPopupCard :showPayPopup.sync="showPayPopup" :pay-data="payData" :pay-url="payUrl"></payPopupCard> -->
  </div>
</template>
<script>
import userMixin from '@/mixin/userMixin'
import { getParam } from '@/lib/utils'
import { newAppBack, appPay, appPaySuccess, toHomePage } from '@/lib/appMethod'
import navBar from '@/components/nav-bar-202210/index'
import goodsItem from '@views/ipad/components/goods-item'
import payPopupCard from '@/components/pay-popup-card'

const miniPath = `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/marketing/shoppingcart-settlement`

export default {
  mixins: [userMixin],
  name: 'shoppingcart-settlement',
  components: {
    goodsItem,
    navBar,
    payPopupCard,
  },
  data() {
    return {
      shopCartInfo: {},
      shopCartList: {},
      totalNum: 0,

      showPayPopup: false,
      payData: {},
      payUrl: '',
    }
  },
  async created() {
    await this.$getAllInfo(['userId'])

    // 支付成功回调 小程序购买成功提示
    appPaySuccess().then(() => {
      this.paySuccessCallback()
    })

    this.shoppingCartId = getParam().shoppingCartId
    this.shopId = getParam().id
    this.shoppingCartUserId = getParam().shoppingCartUserId
    this.inviteTeacherId = getParam().inviteTeacherId

    this.getShopCart() // 详情
    this.getShopCartList() // 列表

    // 获取绑定关系
    this.getBindInfo()
  },
  methods: {
    newAppBack,
    // 获取购物车详情 使用当前shoppingCartId 去匹配
    getShopCart() {
      this.$axios.post(this.baseURLApp + '/ipad/user/teaching/template/getShoppingCarBadge', {
        userId: this.shoppingCartUserId,
      }).then((r) => {
        const res = r.data
        const arr = res.filter((item) => item.shoppingCartId === this.shoppingCartId)
        this.shopCartInfo = arr[0]
      })
    },
    // 获取购物车方案商品列表 使用当前shoppingCartId 去匹配
    getShopCartList() {
      this.$axios.post(this.baseURLApp + '/ipad/user/teaching/template/getShoppingCart', {
        userId: this.shoppingCartUserId,
        shoppingCartId: this.shoppingCartId,
        shopId: this.shopId,
      })
      .then((r) => {
        const res = r.data
        const arr = res.filter((item) => item.shoppingCartId === this.shoppingCartId)

        this.shopCartList = arr[0]

        let goods = []
        this.shopCartList.goodsList.map((item) => {
          goods.push({
            goodsId: item.id,
            goodsName: item.goodsName,
            itemId: item.itemInfos[0].id,
            productId: item.itemInfos[0].productId,
            num: this.shopCartInfo.selectionCount[item.id],
            goodsType: item.itemInfos[0].goodsType
          })
          this.totalNum += this.shopCartInfo.selectionCount[item.id]
        })

        this.multiGoods = goods

      })
    },
    async payAction() {
      const payData = this.getPayParams()

      const url = `${miniPath}&shoppingCartId=${this.shoppingCartId}&id=${this.shopId}&shoppingCartUserId=${this.shoppingCartUserId}`

      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      } else {
        appPaySuccess().then(() => {
          this.paySuccessCallback()
        })
      }

      appPay(payData, url)

    },
    getPayParams() {
      const title = this.multiGoods.length > 1 ? `${this.multiGoods[0].goodsName}等商品` : `${this.multiGoods[0].goodsName}`
      return {
        subject: title,
        extendsJson: {
          appVersion: '2.1.11',
          shopId: this.shopId,
          multiGoods: this.multiGoods,
          from: 'teaching_template',
        },
        totalAmount: this.shopCartInfo.salePrice + '',
        venueId: '31',
        goodsNum: this.totalNum,
        isApp: '1',
        type: 29, // 组合购买
        userId: this.userId,
        token: this.token,
        extendsType: '4',
        mouldId: '',
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: this.appTypeStr === 'mini' ? '商品名称' : title,
      }
    },
    paySuccessCallback() {
      this.$toast('支付成功')
      setTimeout(() => {
        toHomePage()
      }, 2000);
    },
    getBindInfo() {
      this.$axios
        .post(this.baseURLApp + '/userDistribute/bindUser', {
          hasWx: true,
          activityNo: this.shopId, // shopId就是老接口里的activityNo
          userId: this.userId,
          saleTeacherId: this.inviteTeacherId,
        })
        .then((res) => {

        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  background: #f5f5f5;
  * {
    box-sizing: border-box;
  }
  .title {
    padding: 32px;
  }
  .goods-box {
    width: 100%;
    padding: 0 32px;
    margin: 0 0 calc(100px + env(safe-area-inset-bottom));
    .goods-item {
      margin: 0 0 20px;
      padding: 20px 14px 20px 20px;
    }
  }
  .sticky-bottom {
    width: 100%;
    position: sticky;
    bottom: 0;
    left: 0;
    padding: 24px 32px calc(24px + env(safe-area-inset-bottom));
    background-color: #fff;
    .p1 {
      margin: 0 9px 0 0;
    }
    .p2 {
      margin: 0 11px 0 0;
      transform: translateY(10px);
      color: #ee4832;
    }
    .p3 {
      margin: 0 12px 0 0;
      text-decoration: line-through;
    }
    .p4 {
      padding: 0 12px;
      height: 30px;
      background: linear-gradient(90deg, #fc7411 0%, #fc2c11 100%);
      border-radius: 4px;
      color: #fff;
    }
    .button {
      padding: 0 42px;
      height: 96px;
      background: #ffde00;
      border-radius: 8px;
    }
  }
}
</style>
