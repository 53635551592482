import { gotoAppPage, appOpenWeb, gotoCoachDetail, appType, gotoOrderList, goMyMoney, goMiniRecommendedCoach, goVipCenter, gotoVenue } from '../../../lib/appMethod'
import wx from 'weixin-js-sdk'

export default function(obj) { // obj: 服务端返回的跳转对象
  switch (obj.type) {
    case '1':
      gotoAppPage(2, '') // 充值
      break;
    case '3':
    case '1001':
      gotoAppPage(3, '') // 团课主页
      break;
    case '4':
    case '1002':
      gotoAppPage(1, '') // 私教主页
      break;
    case '8':
      gotoCoachDetail(obj.extendJson.teacherId) // 教练主页
      break;
    case '9':
      gotoOrderList() // 订单中心
      break;
    case '10':
      goVipCenter() // 超鹿会员
      break;
    case '11':
      goMyMoney() // 余额明细
      break;
    case '14':
      gotoVenue(1) // 门店列表
      break;
    case '20':
      gotoAppPage(5, '') // 礼品卡商城
      break;
    case '21':
      appOpenWeb(obj.extendJson.title, obj.extendJson.url) // 新开web页
      break;
    case '26':
      gotoAppPage(17, '') // 越练越便宜
      break;
    case '31':
      gotoAppPage(13, '') // 私教全部课程
      break;
    case '1005':
    case '3000':
      gotoAppPage(8, '') // 小班课首页
      break;
    case '3002':
      gotoAppPage(14, '') // 小班课-每日排课
      break;
    case '3006':
      goMiniRecommendedCoach() // 小班课推荐教练页
      break;
    case '3007':
      gotoAppPage(15, '') // 快速约课
      break;
    case '3008':
      gotoAppPage(16, '') // 意见反馈
      break;
    default:
      break;
  }
  // app className的跳转方式
  if (obj.className) {
    const appTypeStr = appType()
    switch (appTypeStr) {
      case 'ios':
        window.webkit.messageHandlers.CustomViewController.postMessage({ "className": obj.className, data })
        // ios的data格式就是一个对象
        break;
      case 'and':
        App.intentToActivity(JSON.stringify({
          "className": obj.className,
          "data": data
          // 安卓的data格式需要一个list，例如：
          // [{
          //   "key":"extra_venue_id",
          //   "value": venueId
          // }]
        }))
        break;
      default:
        wx.navigateTo({
          url: obj.className,
        })
        break;
    }
  }
}

// export default [
//   {type: '1', method: '/pages/subPages/balance/index'}, // 充值
//   {type: '3', method: '/pages/courseList/courseList'}, // 团课主页
//   {type: '4', method: 'gotoAppPage'}, // 私教主页
//   {type: '7', method: '/pages/subPages/packageSell/index'}, // 团课课包 active=0
//   {type: '8', method: '/pages/priPages/coachDetail/coachDetail', needExtendJson: true}, // 教练主页
//   {type: '9', method: '/pages/orderList/orderList'}, // 订单中心
//   {type: '10', method: '/pages/subPages/superDeerVip/index'}, // 钱包
//   {type: '11', method: '/pages/subPages/myBalance/index'}, // 余额明细
//   {type: '12', method: '/pages/subPages/myDeer/index'}, // 鹿分明细
//   {type: '14', method: '/pages/venue/list/index'}, // 门店列表
//   {type: '16', method: '/pages/subPages/camp/index', needExtendJson: true}, // 训练营详情 campAllotId（allotId)  cityId courseId
//   {type: '20', method: '/pages/subPages/giftCard/index'}, // 礼品卡商城
//   {type: '21', method: '/pages/webView/index', needExtendJson: true}, // 网页
//   {type: '22', method: '/pages/leagueLecture/courseDetail/couseDetail', needExtendJson: true}, // 团课指定排班（团课详情页）mouldId=&teacherId=
//   {type: '23', method: '/pages/priPages/appointment/index', needExtendJson: true}, // 私教教练排班页 teacherId
//   {type: '25', method: '/pages/subPages/inviter/index'},
//   {type: '26', method: '/pages/activity/discount/index'},  // 越练越便宜
//   {type: '50', method: '/pages/videoPlayer/index', needExtendJson: true}, // 视频单独播放页 videoPath
//   {type: '3006', method: '/pages/miniCampPages/coachRecommend/index'}, // 小班课 推荐教练
//   {type: '1001', method: '/pages/courseList/courseList'}, // 团课主页
//   {type: '1002', method: '/pages/priCoach/index'}, // 私教主页
//   {type: '1005', method: '/pages/miniCamp/index'}, // 小班课列表
//   {type: '3000', method: '/pages/miniCamp/index'}, // 小班课列表
//   {type: '3001', method: '/pages/miniCampPages/allClass/index'}, // 小班课-全部班级页
//   {type: '3002', method: '/pages/miniCampPages/allSchdule/index'}, // 小班课-全部排课页
//   {type: '3003', method: '/pages/miniCampPages/miniCampDetail/index', needExtendJson: true}, // 小班课-班型详情
//   {type: '3004', method: '/pages/miniCampPages/classDetail/index', needExtendJson: true}, // 小班课-班级详情
//   {type: '3005', method: '/pages/miniCampPages/singleDetail/index', needExtendJson: true}, // 小班课-排课详情
//   {type: '3007', method: '/pages/shortcut/index'}, // 快速约课
//   {type: '3008', method: '/pages/uploadPages/feedback/feedback'}, // 意见反馈
// ]
// const blackList = ['2', '6', '13', '15', '17', '18', '19', '1004', '2001', '2002', '2003'] // 黑名单 app端有 但小程序还没做的（上面注释的部分）



