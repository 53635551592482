<template>
  <div class="container">
    <nav-bar :headerTitle="AI.name" :showBack="true" :show-share="AI.isShare" :header-back="appBack" @right-txt-click="shareShow = true" @setBarHeight="setBarHeight"></nav-bar>
    <div class="co">
      <img class="co-item" @click="toAppPath(item.link)" v-for="(item, index) in AI.contents" :src="item.imgUrl" :key="index" alt="" />
    </div>
    <!-- 门店配置 -->
    <div v-if="venueConfig" class="venue-co" :style="`background-color: ${venueConfig.color}`">
      <div @click="toAppPath(item.link)" class="venue-item" v-for="item in venueConfig.venues" :key="item.venueId">
        <img :src="item.imgUrl" alt="" />
        <div class="info">
          <div class="row-between-center">
            <p class="name f32 fw6">{{ item.venueName }}</p>
            <div class="tag row-center-center f20 fw6">{{ item.openDate }}&nbsp;开业</div>
          </div>
          <p class="address f22 ellipsis">
            <span v-if="item.distanceStr" class="fw6">{{ item.distanceStr }}&nbsp;</span>{{ item.address }}
          </p>
        </div>
      </div>
    </div>
    <!-- 分栏 -->
    <van-sticky :offset-top="barHeight">
      <div class="column-tabs row-between-center">
        <img :style="`width: ${ 100 / columns.length}%;`" @click="columnIndex = index" class="column-item" :src="columnIndex === index ? item.clickImgUrl : item.defaultImgUrl" v-for="(item, index) in columns" :key="item.clickImgUrl" />
      </div>
    </van-sticky>


    <!-- 分栏内容 -->
    <div class="column-co">
      <div v-show="columnIndex === index" v-for="column,index in columns" :key="column.clickImgUrl">
        <div class="co">
          <img class="co-item" @click="toAppPath(item.link)" v-for="(item, index) in column.contents" :src="item.imgUrl" :key="index" alt="" />
        </div>
        <!-- 门店配置 -->
        <div v-if="column.venueConfig" class="venue-co" :style="`background-color: ${column.venueConfig.color}`">
          <div @click="toAppPath(item.link)" class="venue-item" v-for="item in column.venueConfig.venues" :key="item.venueId">
            <img :src="item.imgUrl" alt="" />
            <div class="info">
              <div class="row-between-center">
                <p class="name f32 fw6">{{ item.venueName }}</p>
                <div class="tag row-center-center f20 fw6">{{ item.openDate }}&nbsp;开业</div>
              </div>
              <p class="address f22 ellipsis">
                <span class="fw6">{{ item.distanceStr }}</span
                >&nbsp;{{ item.address }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--  分享  -->
    <common-share :value="shareShow" :shareParams="shareParams" :shareItem="['minifriend']" @close="shareShow = false" @share-success="shareShow = false"></common-share>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { appBack, defaultApp } from '@/lib/appMethod'
import navBar from '@/components/nav-bar-202210/index'
import commonShare from '@/components/commonShare'
import wx from 'weixin-js-sdk'
import jump from './jump'

const webUrl = `${window.location.origin}/#/superdeer-activity/marketing/leaflet`

export default {
  data() {
    return {
      id: '',
      AI: '', // 活动详情
      barHeight: 0,

      venueConfig: {}, // 门店配置
      columns: [], // 分栏

      columnIndex: 0,

      shareShow: false,
      shareParams: {
        title: '',
        miniImage: '',
        path: ``,
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
    }
  },
  mixins: [userMixin],
  components: {
    navBar,
    commonShare,
  },
  async created() {
    await this.$getAllInfo(['userId', 'local'])
    console.log(this.local);
    this.id = this.$route.query.id
    await this.getActivity()

    // 分享的链接
    if (this.AI.isShare) {
      const uu = encodeURIComponent(`${webUrl}`)
      this.shareParams.title = this.AI.appletShareDescribe
      this.shareParams.miniImage = this.AI.appletShareImgUrl
      this.shareParams.path = `/pages/webView/index?webUrl=${uu}&id=${this.id}&location=1`
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })
    }
  },
  methods: {
    appBack,
    setBarHeight(h) {
      this.barHeight = h || 0
    },
    getActivity() {
      return this.$axios
        .post(`${this.baseURLApp}/spreadActivity/getActivityDetail`, {
          id: this.id,
          lat: this.local ? this.local.lat : '',
          lng: this.local ? this.local.lng : ''
        })
        .then((res) => {
          const d = res.data
          this.AI = d
          this.venueConfig = d.venueConfig
          this.columns = d.columns
        })
    },
    toAppPath(obj) {
      if (!obj) return
      jump(obj)
    },
    goWeb() {
      if (this.appTypeStr === 'mini') {
        this.$router.push('/emptyPage?isUndo=1')
      } else {
        defaultApp()
        window.location.href = 'https://superdeer.wjx.cn/vm/P678vXD.aspx'
      }
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  padding: 0 0 calc(env(safe-area-inset-bottom));
  * {
    box-sizing: border-box;
  }
  .co-item {
    width: 100%;
    display: block;
  }

  .venue-co {
    width: 100%;
    padding: 28px 32px;
    .venue-item {
      border-radius: 20px;
      overflow: hidden;
      &:not(:last-child) {
        margin: 0 0 28px;
      }
      img {
        width: 100%;
        height: 160px;
        display: flex;
        object-fit: cover;
      }
      .info {
        background-color: #fff;
        padding: 12px 24px 16px;
        .tag {
          padding: 0 10px;
          height: 36px;
          background: linear-gradient(90deg, #ff7200 0%, #ff350d 100%);
          border-radius: 4px;
          color: #fff;
        }
        .address {
          margin: 16px 0 0;
        }
      }
    }
  }
  .column-co {

  }
  .column-tabs {
    height: 120px;
    background-color: #fff;
    .column-item {
      height: 100%;
      object-fit: contain;
      flex: auto;
    }
  }
}
</style>
