<template lang="">
  <div class="style-three-card col-between-start">
    <div>
      <div v-if="cardObj.plugTags" class="send f22 fw6" :style="`color: ${shopStyle.promoLabel.textColor}; background: linear-gradient(90deg, ${shopStyle.promoLabel.bgTopColor} 0%, ${shopStyle.promoLabel.bgBottomColor} 100%);`">{{ cardObj.plugTags }}</div>
      <div class="good-img"><img :src="cardObj.goodsImg" /></div>
      <div class="shu-name f26 fw6 ellipsis">{{ cardObj.goodsName }}</div>
      <div class="invalid-time f18" v-if="cardObj.hint">
        {{ cardObj.hint }}
      </div>
      <div class="label-line f20" v-if="cardObj.productTags.length">
        <div class="label-model" :style="`color: ${shopStyle.goodsLabel.textColor}; background: linear-gradient(90deg, ${shopStyle.goodsLabel.bgTopColor} 0%, ${shopStyle.goodsLabel.bgBottomColor} 100%);`" v-for="item,index in cardObj.productTags" :key="index">
          {{ item }}
        </div>
      </div>
      <div class="price-line">
        <span v-if="priceStatus.showAfterVoucher" class="dl f22 fw6">券后价</span>
        <span class="dl f22 fw6">¥</span>
        <span class="price f32 fw6">{{ priceStatus.discountPrice === undefined ? cardObj.salePrice : priceStatus.discountPrice }}<span v-if="priceStatus.showStart || cardObj.showStart">起</span></span>
        <p v-if="cardObj.displayOriginPrice" class="ori-price f20">¥{{ cardObj.originPrice }}</p>
      </div>
    </div>
    <div :class="['buy', 'f24', 'fw6', 'row-center-center']" :style="buttonColor" @click="buyCard">
    {{ buttonText }}
    </div>
  </div>
</template>
<script>

import buyStatus from '../config'

export default {
  data() {
    return {

    }
  },
  props: {
    shopStyle: {
      value: {
        promoLabel: {},
        goodsLabel: {}
      },
      type: Object
    },
    cardObj: {
      type: Object
    },
    status: {
      type: String
    },
    priceStatus: {
      type: Object,
      default() {
        return { showAfterVoucher: false }
      }
    },
    activityStatus: {
      type: String
    }
  },
  computed: {
    buttonText() {
      if (buyStatus[this.status]) {
        return buyStatus[this.status].name
      }
      return this.activityStatus === 'notin' ? '待开启' : this.activityStatus === 'end' ? '已结束' : '马上抢'
    },
    buttonColor() {
      if (!this.shopStyle.buyButtonFailure) return ''
      if (this.status && this.status != 'CAN_BUY') {
        return `color: ${this.shopStyle.buyButtonFailure.textColor}; background: linear-gradient(180deg, ${this.shopStyle.buyButtonFailure.bgTopColor} 0%, ${this.shopStyle.buyButtonFailure.bgBottomColor} 100%)`
      }
      return ['notin', 'end'].includes(this.activityStatus) ? `color: ${this.shopStyle.buyButtonFailure.textColor}; background: linear-gradient(180deg, ${this.shopStyle.buyButtonFailure.bgTopColor} 0%, ${this.shopStyle.buyButtonFailure.bgBottomColor} 100%)`
       : `color: ${this.shopStyle.buyButtonEffective.textColor}; background: linear-gradient(180deg, ${this.shopStyle.buyButtonEffective.bgTopColor} 0%, ${this.shopStyle.buyButtonEffective.bgBottomColor} 100%)`
    }
  },
  methods: {
    buyCard() {
      this.$emit('buyCard')
    }

  },
};
</script>
<style lang="less">
.style-three-card {
  width: 204px;
  min-height: 396px;
  border-radius: 16px;
  background: #fff;
  padding: 9px 9px 10px;
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;

  .good-img {
    width: 100%;
    img {
      width: 100%;
      display: block;
      border-radius: 16px;
    }
  }

  .shu-name {
    color: #242831;
    margin-top: 14px;
  }

  .buy {
    width: 100%;
    margin: 14px 0 0;
    height: 56px;
    background: linear-gradient(0deg, #f35947 0%, #fba27b 100%);
    border-radius: 28px;
    color: #fff;

  }

  .gray {
    color: #696c6f;
    background: linear-gradient(0deg, #d7d7d7 0%, #cfd0d2 100%);
  }

  .invalid-time {
    color: #242831;
    margin-top: 8px;
  }

  .label-line {
    white-space: nowrap;
    margin-top: 12px;

    .label-model {
      padding: 0 6px;
      height: 30px;
      background: linear-gradient(90deg, #ffe8b5 0%, #ffd7c3 100%);
      border-radius: 4px;
      color: #b12e1d;
      margin-right: 10px;
      line-height: 30px;
      display: inline-flex;
      max-width: 100%;
      overflow: hidden;
    }
  }

  .price-line {
    margin-top: 8px;
    line-height: 1;

    .dl {
      color: #ee4832;
      margin-right: 4px;
    }

    .price {
      color: #ee4832;
      margin-right: 9px;
      position: relative;
      top: 2px;
    }

    .ori-price {
      text-decoration: line-through;
      color: #6c727a;
    }
  }

  .send {
    position: absolute;
    left: 0;
    top: 0;
    border-bottom-right-radius: 16px;
    background: linear-gradient(90deg, #3889f0 0%, #a26bdc 99%);
    padding: 0 18px;
    line-height: 38px;
    color: #fff;
  }
}
</style>
